.container {
  width: 100vw;
  height: 100vh;
  background-color: #212121;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;
  color: #fff;
}

.canvas {
  border-radius: 42px;
  background: linear-gradient(315deg, #232323, #1e1e1e);
  box-shadow: -9px -9px 18px #1b1b1b,
    9px 9px 18px #272727;
}
.stats{
    width: 50%;
}
.progressBar {
  width: 100%;
  height: 20%;
  margin-top: 1rem;
}
.loader{
    width: 70%;
    margin-top:2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.hidden{
  visibility: hidden;
}